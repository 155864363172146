import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import axiosInstance from "../../../common/utils/axios";
import LoadingOverlayWrapper from "react-loading-overlay-ts";
import { showMessage } from "../../../Utility";

const IndividualForm = () => {
  const [incidentOptions, setIncidentOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
    clearErrors,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      incidentOptions: incidentOptions,
    },
  });

  const handleFormSubmit1 = (payload) => {
    axiosInstance
      .post("individual-cyber-incidents", {
        data: { ...payload, issue_options: payload?.issue_options?.join(",") },
      })
      .then((resp) => {
        // add notification
      })
      .catch((err) => {
        // add notification
        console.log("individual-cyber-incidents post error", err);
      })
      .finally(() => {});
  };

  const handleFormSubmit = (payload) => {
    setLoading(true);
    axiosInstance
      .post("send-email", {
        data: {
          ...payload,
          type: "individual_incident",
          subject: "Signaler un incident de cybersécurité pour un particulier",
        },
      })
      .then((resp) => {
        setLoading(false);
        if (resp?.data?.success) {
          showMessage("Votre message a bien été envoyé", { type: "success" });
          reset();
          setIncidentOptions([]);
        }
      })
      .catch((err) => {
        setLoading(false);
        showMessage("Une erreur est survenue", "error");
        console.log("entreprise-cyber-incidents post error", err);
      });
  };

  return (
    <LoadingOverlayWrapper active={loading} spinner>
      <Form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="row">
          <div className="col-md-6">
            <Form.Group controlId="name">
              <Form.Label>Nom</Form.Label>
              <Form.Control
                {...register("name", { required: true })}
                className={
                  errors["name"] ? "form-control is-invalid" : "form-control"
                }
              />
            </Form.Group>
            <Form.Group controlId="surname">
              <Form.Label>Prénom</Form.Label>
              <Form.Control
                {...register("surname", { required: true })}
                className={
                  errors["surname"] ? "form-control is-invalid" : "form-control"
                }
              />
            </Form.Group>
            <Form.Group controlId="mobile">
              <Form.Label>Numéro du Telephone</Form.Label>
              <Form.Control
                {...register("mobile", { required: true })}
                className={
                  errors["mobile"] ? "form-control is-invalid" : "form-control"
                }
              />
            </Form.Group>
            <Form.Group controlId="email">
              <Form.Label>E-mail</Form.Label>
              <Form.Control
                {...register("email", { required: true })}
                className={
                  errors["email"] ? "form-control is-invalid" : "form-control"
                }
              />
            </Form.Group>
            {/* <Form.Group controlId="incidentOptions" className="mt-3"> */}
            <Form.Label
              className={
                errors["issue_options"]
                  ? "mt-3 form-control is-invalid"
                  : "mt-3"
              }
              {...register("issue_options", {
                required: true,
                validate: () => incidentOptions.length > 0,
              })}
            >
              Veuillez choisir une ou plusieurs causes de cyber incident
            </Form.Label>
            {[
              "Installation de logiciel",
              "Mise à jour de logiciel",
              "Téléchargement d'un fichier suspect depuis Internet",
              "Télécharger et exécuter une pièce jointe suspecte d'un email",
              "Cliquer sur un lien vers un site web suspect",
              "Exécuter une macro dans une feuille de calcul",
              "Lancement d'un support externe",
              "Utilisation abusive d'identifiants perdus",
              "Transfert d'identifiants à un tiers",
              "Installation d'un plugin/add-on pour un navigateur web",
              "Lancement d'un site web inhabituel",
              "Accès physique à un poste de travail",
              ,
            ].map((option) => (
              <div
                onClick={() => {
                  const newValue = incidentOptions?.includes(option)
                    ? incidentOptions.filter((item) => item !== option)
                    : [...incidentOptions, option];

                  clearErrors("issue_options");
                  setIncidentOptions(newValue);
                  setValue("issue_options", newValue);
                }}
              >
                <Form.Check
                  key={option}
                  type="checkbox"
                  label={option}
                  value={option}
                  checked={incidentOptions?.includes(option)}
                />
              </div>
            ))}
            {/* </Form.Group> */}
          </div>
          <div className="col-md-6">
            <Form.Group controlId="description">
              <Form.Label>Brève description de l'incident</Form.Label>
              <Form.Control
                as="textarea"
                rows={8}
                {...register("description", { required: true })}
                className={
                  errors["description"]
                    ? "form-control is-invalid"
                    : "form-control"
                }
              />
            </Form.Group>
            <Form.Group controlId="remediation">
              <Form.Label>
                Décrire les activités de remédiation jusqu'à présent (le cas
                échéant)
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={10}
                {...register("remediation", { required: true })}
                className={
                  errors["remediation"]
                    ? "form-control is-invalid"
                    : "form-control"
                }
              />
            </Form.Group>
            <Form.Group controlId="additional_info">
              <Form.Label>
                Fournir toute information supplémentaire (facultatif)
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                {...register("additional_info", { required: true })}
                className={
                  errors["additional_info"]
                    ? "form-control is-invalid"
                    : "form-control"
                }
              />
            </Form.Group>
          </div>
        </div>
        <div className="col-md-12 m-4">
          <Button type="submit">Soumettre</Button>
        </div>
      </Form>
    </LoadingOverlayWrapper>
  );
};

export default IndividualForm;

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import axiosInstance from "../../../common/utils/axios";
import LoadingOverlayWrapper from "react-loading-overlay-ts";
import { showMessage } from "../../../Utility";

const CompanyForm = () => {
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm();

  const handleFormSubmit1 = (payload) => {
    axiosInstance
      .post("entreprise-cyber-incidents", {
        data: payload,
      })
      .then((resp) => {
        // add notification
        reset();
      })
      .catch((err) => {
        // add notification
        console.log("entreprise-cyber-incidents post error", err);
      })
      .finally(() => {});
  };

  const handleFormSubmit = (payload) => {
    setLoading(true);
    axiosInstance
      .post("send-email", {
        data: {
          ...payload,
          type: "company_incident",
          subject: "Signaler un incident de cybersécurité pour Entreprise",
        },
      })
      .then((resp) => {
        setLoading(false);
        if (resp?.data?.success) {
          showMessage("Votre message a bien été envoyé", "success");
          reset();
        }
      })
      .catch((err) => {
        setLoading(false);
        showMessage("Une erreur est survenue", "error");
        console.log("entreprise-cyber-incidents post error", err);
      });
  };

  return (
    <LoadingOverlayWrapper active={loading} spinner>
      <Form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="row">
          <div className="col-md-6">
            <Form.Group controlId="title">
              <Form.Label>titre</Form.Label>
              <div className="location-box">
                <div className="select-box">
                  <select
                    {...register("title", { required: true })}
                    placeholder="choisir titre"
                    className={
                      errors["title"]
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                  >
                    <option disabled selected value="">
                      choisir titre
                    </option>
                    <option value="Mr">Mr</option>
                    <option value="Mrs">Mrs</option>
                    <option value="Miss">Miss</option>
                    <option value="Dr">Dr</option>
                  </select>
                </div>
              </div>
            </Form.Group>
            <Form.Group controlId="name">
              <Form.Label>Nom</Form.Label>
              <Form.Control
                {...register("name", { required: true })}
                className={
                  errors["name"] ? "form-control is-invalid" : "form-control"
                }
              />
            </Form.Group>
            <Form.Group controlId="surname">
              <Form.Label>Prénom</Form.Label>
              <Form.Control
                {...register("surname", { required: true })}
                className={
                  errors["surname"] ? "form-control is-invalid" : "form-control"
                }
              />
            </Form.Group>
            <Form.Group controlId="job_position">
              <Form.Label>Poste</Form.Label>
              <Form.Control
                {...register("job_position", { required: true })}
                className={
                  errors["job_position"]
                    ? "form-control is-invalid"
                    : "form-control"
                }
              />
            </Form.Group>
            <Form.Group controlId="mobile">
              <Form.Label>Numéro du Telephone</Form.Label>
              <Form.Control
                {...register("mobile", { required: true })}
                className={
                  errors["mobile"] ? "form-control is-invalid" : "form-control"
                }
              />
            </Form.Group>
            <Form.Group controlId="email">
              <Form.Label>E-mail</Form.Label>
              <Form.Control
                {...register("email", { required: true })}
                className={
                  errors["email"] ? "form-control is-invalid" : "form-control"
                }
              />
            </Form.Group>
            <Form.Group controlId="company_name">
              <Form.Label>Nom de l'entreprise</Form.Label>
              <Form.Control
                {...register("company_name", { required: true })}
                className={
                  errors["company_name"]
                    ? "form-control is-invalid"
                    : "form-control"
                }
              />
            </Form.Group>
            <Form.Group controlId="organization_type">
              <Form.Label>Type d'organisation</Form.Label>
              <div className="location-box">
                <div className="select-box">
                  <select
                    {...register("organization_type", { required: true })}
                    className={
                      errors["organization_type"]
                        ? "selectmenu wide form-control is-invalid"
                        : "selectmenu wide form-control"
                    }
                  >
                    <option disabled selected value="">
                      Sélectionnez le type d'organisation
                    </option>
                    <option>IT</option>
                    <option>Chemical</option>
                    <option>Defense</option>
                    <option>Energy</option>
                    <option>Other</option>
                  </select>
                </div>
              </div>
            </Form.Group>
            <Form.Group controlId="no_of_people">
              <Form.Label>
                Décrivez le nombre approximatif de personnes touchées par
                l'incident
              </Form.Label>
              <Form.Control
                as="select"
                name="no_of_people"
                {...register("no_of_people", { required: true })}
                placeholder="hello"
                className={
                  errors["no_of_people"]
                    ? "form-control is-invalid"
                    : "form-control"
                }
              >
                <option value="">choisir...</option>
                <option value="0-10">0 to 10</option>
                <option value="11-20">11 to 20</option>
                <option value="21-100">21 to 100</option>
                <option value="101-500">101 to 500</option>
                <option value="above-500">Above 500</option>
              </Form.Control>
            </Form.Group>
          </div>
          <div className="col-md-6">
            <Form.Group controlId="incident_date">
              <Form.Label>
                Décrire la date et l'heure exactes ou approximatives auxquelles
                un incident s'est produit/détecté
              </Form.Label>
              <Form.Control
                {...register("incident_date", { required: true })}
                type="datetime-local"
                className={
                  errors["incident_date"]
                    ? "form-control is-invalid"
                    : "form-control"
                }
              />
            </Form.Group>
            <Form.Group controlId="describe_incident_cause">
              <Form.Label>
                Décrire la cause de l'incident (si connue){" "}
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={6}
                {...register("describe_incident_cause", { required: true })}
                className={
                  errors["describe_incident_cause"]
                    ? "form-control is-invalid"
                    : "form-control"
                }
              />
            </Form.Group>
            <Form.Group controlId="describe_remediation">
              <Form.Label>
                Décrire les activités de remédiation jusqu'à présent (le cas
                échéant)
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={7}
                {...register("describe_remediation", { required: true })}
                className={
                  errors["describe_remediation"]
                    ? "form-control is-invalid"
                    : "form-control"
                }
              />
              <Form.Group controlId="additional_info">
                <Form.Label>
                  Fournissez toute information supplémentaire (facultatif)
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  {...register("additional_info", { required: true })}
                  className={
                    errors["additional_info"]
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                />
              </Form.Group>
            </Form.Group>
          </div>
        </div>

        <div className="col-md-12 mt-4">
          <Button type="submit">Soumettre</Button>
        </div>
      </Form>
    </LoadingOverlayWrapper>
  );
};

export default CompanyForm;

import React from 'react';
import HeaderThree from '../common/header/HeaderThree';
import Breadcrumb from '../common/breadcrumb/Breadcrumb';
import ContactMap from '../components/contact/ContactMap';
import ContactForm from '../components/contact/ContactForm';
import FooterOne from '../common/footer/FooterOne';

const ContactPage = () => {
  return (
    <>
      <HeaderThree />
      <Breadcrumb currentPage='Contact' />
      <ContactForm />
      <ContactMap />

      {/* <BrandOne /> */}
      <FooterOne />
    </>
  );
};

export default ContactPage;

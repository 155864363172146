import React from 'react';
import MainLayout from '../../layout/MainLayout';
import { Container, Col, Row } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import './index.css';
import {
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  FacebookIcon,
  LinkedinIcon,
  WhatsappIcon,
} from 'react-share';
// - Critique    ( low) 0.1 - 3.9   green  036E3C
// - Moyennement critique  ( Meduim  ) 4.0 - 6.9 yellow  FCED22
// - Très critique  (High) 7.0 - 8.9  orange FBB03B
// - critical 9.0 - 10.0  red  C1282E

const data = [
  {
    id: 1,
    score: '7.0 - 8.0',
    criticity: 'High',
    reference: 'ANSICE/CERT/ALERT/19/2024',
    date: '19 Nov 2024',
    title: 'Vulnérabilités zero-day corrigées dans les appareils Apple',
    link: 'assets/alert/Apple.pdf',
    criticityColor: '#FBB03B',
    description:
      'L’Agence Nationale de Sécurité Informatique et de Certification Électronique (ANSICE) informe tous les utilisateurs des appareils Apple (iPhone, iPad, Mac, etc.) de la découverte et de l’exploitation active de deux vulnérabilités critiques de type zero-day..',
  },

  {
    id: 2,
    score: '9.0 - 10',
    criticity: 'Critical',
    reference: 'ANSICE/ALERTE/16/2024',
    date: '01 juillet 2024',
    title: 'MULTIPLES VULNERABILITES DANS LES PRODUITS CISCO',
    link: 'assets/alert/MULTIPLES-VULNERABILITES-DANS.pdf',
    criticityColor: '#C1282E',
    description:
      "Le 24 avril 2024, Cisco a publié trois avis de sécurité concernant des vulnérabilités affectant les équipements de sécurité ASA et FTD. Deux d'entre eux concernent les vulnérabilités CVE-2024-20353 et CVE-2024-20359 qui sont activement exploitées dans le cadre d'attaques ciblées.  ",
  },

  {
    id: 3,
    score: '9.0-10',
    criticity: 'Critical',
    reference: 'ANSICE/ALERTE/14/2024',
    date: '18 juin 2024',
    title: 'Multiples vulnérabilités dans les produits VMware',
    link: 'assets/alert/produits-VMware.pdf',
    criticityColor: '#C1282E',
    description:
      'De multiples vulnérabilités ont été découvertes dans les produits VMware. Elles permettent à un attaquant de provoquer une exécution de code arbitraire à distance et une élévation de privilèges.',
  },
  {
    id: 4,
    score: '7.8-8.8',
    criticity: 'High',
    reference: 'ANSICE/ALERTE/17/2024',
    date: '12 juillet 2024',
    title: 'Vulnérabilité critique affectant les serveurs de messagerie Exim',
    link: 'assets/alert/Exim.pdf',
    criticityColor: '#FBB03B',
    description:
      "Exim annonce la correction d’une vulnérabilité critique affectant les versions susmentionnées de son produit Exim transfer agent. L'exploitation de cette vulnérabilité peut permettre à un attaquant distant de contourner les mesures de sécurité.",
  },

  {
    id: 5,
    score: '7.0-8.0',
    criticity: 'High',
    reference: 'ANSICE/ALERTE/12/2024',
    date: '03 juillet 2024',
    title: 'Vulnérabilité dans OpenSSH',
    link: 'assets/alert/OpenSSH.pdf',
    criticityColor: '#FBB03B',
    description:
      "Cette vulnérabilité permet à un attaquant non authentifié d'exécuter du code arbitraire à distance avec les privilèges root.L'éditeur précise que les versions 8.5p1 à 9.7p1 sont vulnérables, de manière confirmée, sur des systèmes Linux 32 bits avec la glibc et l'ASLR activé. Toutefois, il est précisé que l'exploitation nécessite entre six et huit heures de connexions continues. De plus, il spécule que ces attaques pourraient être optimisées pour être plus rapides, à plus forte raison lorsque l'ASLR est désactivé.",
  },

  {
    id: 6,
    score: '7.8-8.9',
    criticity: 'High',
    reference: 'ANSICE/ALERTE/18/2024',
    date: '15 juillet 2024',
    title: 'Vulnérabilités affectant des routeurs de Netgear',
    link: 'assets/alert/routeurs.pdf',
    criticityColor: '#FBB03B',
    description:
      "Netgear annonce la correction de trois vulnérabilités affectant ses routeurs susmentionnés. L'exploitation de ces vulnérabilités peut permettre à un attaquant de contourner l’authentification, d’exécuter du code arbitraire ou d’injecter du code dans une page.",
  },

  {
    id: 5,
    score: '7.8-8.9',
    criticity: 'High',
    reference: 'ANSICE/ALERTE/13/2024',
    date: '13 Juin 2024',
    title: 'Vulnérabilités affectant le navigateur Mozilla Firefox',
    link: 'assets/alert/Mozilla.pdf',
    criticityColor: '#FBB03B',
    description:
      'Mozilla Foundation annonce la disponibilité d’une mise à jour de sécurité permettant la correction de plusieurs vulnérabilités au niveau du navigateur Mozilla Firefox. L’exploitation de ces vulnérabilités peut permettre à un attaquant distant d’exécuter du code arbitraire, d’accéder à des données confidentielles ou de contourner des mesures de sécurité.',
  },
  {
    id: 7,
    score: '7.0 - 8.0',
    criticity: 'High',
    reference: 'ANSICE/CERT/ALERT/15/2024',
    date: '19 juin 2024',
    title: 'Google Chrome',
    link: 'assets/alert/Multiples-Google-Chrome.pdf',
    criticityColor: '#FBB03B',
    description:
      "De multiples vulnérabilités ont été découvertes dans Google Chrome. Elles permettent à un attaquant de provoquer un problème de sécurité non spécifié par l'éditeur.",
  },
  // Add more rows as necessary...
];

const ExpandedComponent = ({ data }) => <div>{data.description}</div>;

const columns = [
  {
    name: 'Titre',
    selector: (row) => row.title,
    sortable: true,
    width: '300px',
  },
  {
    name: 'Score CVSS',
    selector: (row) => row.score,
    sortable: true,
    width: '150px',
  },
  {
    name: 'Criticité',
    selector: (row) => row.criticity,
    sortable: true,
    width: '150px',

    cell: (row) => (
      <div
        style={{
          backgroundColor: row.criticityColor,
          whiteSpace: 'nowrap',
          verticalAlign: 'middle',
        }}
      >
        {row.criticity}
      </div>
    ),
  },
  {
    name: 'Référence',
    selector: (row) => row.reference,
    sortable: true,
    width: '200px',
  },
  {
    name: 'Date de publication',
    selector: (row) => row.date,
    sortable: true,
  },

  {
    name: 'PDF',
    selector: (row) => row.link,
    cell: (row) => (
      <a
        style={{
          color: 'blue',
          whiteSpace: 'nowrap',
          verticalAlign: 'middle',
        }}
        href={row.link}
        target='_blank'
        rel='noopener noreferrer'
      >
        <span className='icon-document'></span>
      </a>
    ),
  },

  {
    name: 'Partager',
    cell: (row) => {
      console.log(`${window.location.origin}/${row?.link}`);
      return (
        <div>
          <FacebookShareButton
            // https://king-prawn-app-eoas5.ondigitalocean.app/assets/alert/Multiples-Google-Chrome.pdf
            // url={`http://localhost:3000/alerts/${row.id}`}
            url={`${window.location.origin}/${row?.link}`}
            quote={row.title}
            hashtag={`#${row.title}`}
          >
            <FacebookIcon size={20} round={true} />
          </FacebookShareButton>
          <LinkedinShareButton
            // url={`${config.HOST_URL}/alerts/${row?.link}`}
            url={`${window.location.origin}/${row?.link}`}
            // url={`http://localhost:3000/alerts/${row.id}`}
            title={row.title}
            summary={row.description}
          >
            <LinkedinIcon className='m-1' size={20} round={true} />
          </LinkedinShareButton>
          <WhatsappShareButton
            url={`${window.location.origin}/${row?.link}`}
            // url={`${config.HOST_URL}/alerts/${row?.link}`}
            title={row.title}
            separator={' '}
          >
            <WhatsappIcon size={20} round={true} />
          </WhatsappShareButton>
        </div>
      );
    },
  },
];

const AlertsPage = () => {
  return (
    <MainLayout>
      <section className=''>
        <div className='p-5'>
          <div className='sec-title text-center'>
            <div className='sec-title__tagline'>
              <span className='left'></span>
              <h6>ALERTES</h6>
              <span className='right'></span>
            </div>
            <div className='text-center'>
              <h3 style={{ color: '#4397d6' }}>
                BULLETIN DES ALERTES DE SÉCURITÉ INFORMATIQUE
              </h3>
            </div>
          </div>

          <Container>
            <Row>
              <Col xl={12} lg={12}>
                <div className='mt-2'>
                  <Row>
                    <ul style={{ float: 'right', listStyleType: 'none' }}>
                      <li style={{ color: '#39B549' }}>- Low (0.1 - 3.9) </li>
                      <li style={{ color: '#FCED22' }}>
                        - Meduim (4.0 - 6.9){' '}
                      </li>
                      <li style={{ color: '#FBB03B' }}>- High (7.0 - 8.9)</li>
                      <li style={{ color: '#FF0101' }}>
                        - Critical (9.0 - 10){' '}
                      </li>
                    </ul>
                  </Row>
                  <DataTable
                    columns={columns}
                    data={data}
                    highlightOnHover={true}
                    pagination
                    expandableRows
                    expandableRowsComponent={ExpandedComponent}
                    expandOnRowClicked={true}
                    responsive={true}
                    striped={true}
                    fixedHeader={true}
                    fixedHeaderScrollHeight={'400px'}
                    subHeader={true}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </MainLayout>
  );
};

export default AlertsPage;

import React from 'react';
import './style.css';
export default class FeatureOne extends React.Component {
  render() {
    return (
      <>
        <section className='feature-one-sec'>
          <div className='container'>
            <div className='row'>
              <div className='col-xl-12 col-lg-12'>
                <div className='feature-one__single'>
                  {/* <div className='shape1'></div> */}
                  <div className='feature-one__single-inner'>
                    <div className='feat-text'>
                      <p>
                        {' '}
                        L’Agence Nationale de Sécurité Informatique et de
                        Certification Électronique <b>(ANSICE) </b>
                        est un établissement public créé par la loi
                        <b> N°006/PR/2015 du 10 Février 2015 </b> modifiée. Elle
                        exerce ses missions sous la tutelle du Ministère de la
                        Sécurité Publique et de l’Immigration.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

import React from 'react';
import BrandOne from '../../components/brand/BrandOne';
import MainLayout from '../../layout/MainLayout';
import FeatureOne from '../../components/features/FeatureOne';
import Missions from './Missions';
import DirectionInfo from './DirectionInfo';
import Carousel from './Carousel';
const HomePage = () => {
  return (
    <MainLayout>
      <Carousel />
      {/* <CounterO ne /> */}
      <FeatureOne />
      <Missions />
      <DirectionInfo />
      <BrandOne />
    </MainLayout>
  );
};

export default HomePage;

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import axiosInstance from "../../../common/utils/axios";
import LoadingOverlayWrapper from "react-loading-overlay-ts";
import { showMessage } from "../../../Utility";

const PublicInstitutionForm = () => {
  const [incidentOptions, setIncidentOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
    clearErrors,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      issue_options: incidentOptions,
    },
  });

  const handleFormSubmit1 = (payload) => {
    axiosInstance
      .post("public-cyber-incidents", {
        data: { ...payload, issue_options: payload?.issue_options?.join(",") },
      })
      .then((resp) => {
        // add notification
        setIncidentOptions([]);
        reset();
      })
      .catch((err) => {
        // add notification
        console.log("public-cyber-incidents post error", err);
      })
      .finally(() => {});
  };

  const handleFormSubmit = (payload) => {
    setLoading(true);
    axiosInstance
      .post("send-email", {
        data: {
          ...payload,
          type: "public_incident",
          subject:
            "Signaler un incident de cybersécurité pour Institution Publique",
        },
      })
      .then((resp) => {
        setLoading(false);
        if (resp?.data?.success) {
          showMessage("Votre message a bien été envoyé", "success");
          setIncidentOptions([]);
          reset();
        }
      })
      .catch((err) => {
        setLoading(false);
        showMessage("Une erreur est survenue", "error");
        console.log("internship-forms post error", err);
      });
  };

  return (
    <LoadingOverlayWrapper active={loading} spinner>
      <Form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="row">
          <div className="col-md-6">
            <Form.Group controlId="name">
              <Form.Label>Nom</Form.Label>
              <Form.Control
                {...register("name", { required: true })}
                className={
                  errors["name"] ? "form-control is-invalid" : "form-control"
                }
              />
            </Form.Group>
            <Form.Group controlId="surname">
              <Form.Label>Prénom</Form.Label>
              <Form.Control
                {...register("surname", { required: true })}
                className={
                  errors["surname"] ? "form-control is-invalid" : "form-control"
                }
              />
            </Form.Group>
            <Form.Group controlId="mobile">
              <Form.Label>Numéro du Telephone</Form.Label>
              <Form.Control
                {...register("mobile", { required: true })}
                className={
                  errors["mobile"] ? "form-control is-invalid" : "form-control"
                }
              />
            </Form.Group>
            <Form.Group controlId="email">
              <Form.Label>E-mail</Form.Label>
              <Form.Control
                {...register("email", { required: true })}
                className={
                  errors["email"] ? "form-control is-invalid" : "form-control"
                }
              />
            </Form.Group>
            <Form.Group controlId="job_position">
              <Form.Label>Poste</Form.Label>
              <Form.Control
                {...register("job_position", { required: true })}
                className={
                  errors["job_position"]
                    ? "form-control is-invalid"
                    : "form-control"
                }
              />
            </Form.Group>
            <Form.Group controlId="institution_name">
              <Form.Label>Nom complet de l'institution</Form.Label>
              <Form.Control
                {...register("institution_name", { required: true })}
                className={
                  errors["institution_name"]
                    ? "form-control is-invalid"
                    : "form-control"
                }
              />
            </Form.Group>

            <Form.Group controlId="address">
              <Form.Label>Adresse complète</Form.Label>
              <Form.Control
                as="textarea"
                rows={8}
                {...register("address", { required: true })}
                className={
                  errors["address"] ? "form-control is-invalid" : "form-control"
                }
              />
            </Form.Group>
            <Form.Label
              className={
                errors["issue_options"]
                  ? "mt-3 form-control is-invalid"
                  : "mt-3"
              }
              {...register("issue_options", {
                required: true,
                validate: () => incidentOptions.length > 0,
              })}
            >
              Décrire la cause de l'incident (si connue)
            </Form.Label>
            {[
              "Installation de logiciel",
              "Mise à jour de logiciel",
              "Téléchargement d'un fichier suspect depuis Internet",
              "Télécharger et exécuter une pièce jointe suspecte d'un email",
              "Cliquer sur un lien vers un site web suspect",
              "Exécuter une macro dans une feuille de calcul",
              "Lancement d'un support externe",
              "Utilisation abusive d'identifiants perdus",
              "Transfert d'identifiants à un tiers",
              "Installation d'un plugin/add-on pour un navigateur web",
              "Lancement d'un site web inhabituel",
              "Accès physique à un poste de travail",
            ].map((option) => (
              <div
                onClick={() => {
                  const newValue = incidentOptions?.includes(option)
                    ? incidentOptions.filter((item) => item !== option)
                    : [...incidentOptions, option];
                  clearErrors("issue_options");
                  setIncidentOptions(newValue);
                  setValue("issue_options", newValue);
                }}
              >
                <Form.Check
                  key={option}
                  type="checkbox"
                  label={option}
                  value={option}
                  checked={incidentOptions?.includes(option)}
                />
              </div>
            ))}
          </div>
          <div className="col-md-6">
            <Form.Group controlId="incident_description">
              <Form.Label>Brève description de l'incident</Form.Label>
              <Form.Control
                as="textarea"
                rows={9}
                {...register("incident_description", { required: true })}
                className={
                  errors["incident_description"]
                    ? "form-control is-invalid"
                    : "form-control"
                }
              />
            </Form.Group>
            <Form.Group controlId="remediation">
              <Form.Label>
                Décrire les activités de remédiation jusqu'à présent (le cas
                échéant)
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={8}
                {...register("remediation", { required: true })}
                className={
                  errors["remediation"]
                    ? "form-control is-invalid"
                    : "form-control"
                }
              />
            </Form.Group>

            <Form.Group controlId="no_of_people">
              <Form.Label>
                Décrivez le nombre approximatif de personnes touchées par
                l'incident
              </Form.Label>
              <Form.Control
                as="select"
                name="no_of_people"
                {...register("no_of_people", { required: true })}
                placeholder="hello"
                className={
                  errors["no_of_people"]
                    ? "form-control is-invalid"
                    : "form-control"
                }
              >
                <option value="">Choisir...</option>
                <option value="0-10">10 à 20</option>
                <option value="11-20">11 à 20</option>
                <option value="21-100">21 à 100</option>
                <option value="101-500">101 à 500</option>
                <option value="above-500">{"=> 500"}</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="incident_date">
              <Form.Label>
                Décrire la date et l'heure exactes ou approximatives auxquelles
                un incident s'est produit. détecté
              </Form.Label>
              <Form.Control
                {...register("incident_date", { required: true })}
                type="datetime-local"
                className={
                  errors["incident_date"]
                    ? "form-control is-invalid"
                    : "form-control"
                }
              />
            </Form.Group>
            <Form.Group controlId="geographical_limit">
              <Form.Label>
                Décrire les limites géographiques de la zone touchée par le
                incident
              </Form.Label>
              <Form.Control
                as="select"
                name="geographical_limit"
                {...register("geographical_limit", { required: true })}
                placeholder="hello"
                className={
                  errors["geographical_limit"]
                    ? "form-control is-invalid"
                    : "form-control"
                }
              >
                <option value="">Choisir...</option>
                <option value="regional">Regional</option>
                <option value="national">National</option>
                <option value="international">International</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="affected_institutions">
              <Form.Label>
                L’incident a-t-il affecté d’autres institutions ? Si oui,
                énumérez-les.
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                {...register("affected_institutions", { required: true })}
                className={
                  errors["affected_institutions"]
                    ? "form-control is-invalid"
                    : "form-control"
                }
              />
            </Form.Group>
            <Form.Group controlId="additional_info">
              <Form.Label>
                Fournir toute information supplémentaire (facultatif)
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={6}
                {...register("additional_info", { required: true })}
                className={
                  errors["additional_info"]
                    ? "form-control is-invalid"
                    : "form-control"
                }
              />
            </Form.Group>
          </div>
        </div>
        <Button type="submit mt-4 ">Soumettre</Button>
      </Form>
    </LoadingOverlayWrapper>
  );
};

export default PublicInstitutionForm;

import React from 'react';
import { Link } from 'react-router-dom';

export default class LogoWhite extends React.Component {
  render() {
    let publicUrl = '/';
    return (
      <>
        <div className='logo'>
          <Link to={`/`}>
            <img
              src={publicUrl + 'assets/images/resources/ansice.png'}
              alt='Logo'
              width={80}
              heigh={80}
            />
          </Link>
        </div>
      </>
    );
  }
}

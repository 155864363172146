import { toast } from "react-toastify";

// Utility.js
export function removeExtraSpaces(text) {
  return text.replace(/\s+/g, " ").trim();
}

export function showMessage(message, type = "defaut") {
  toast(message, { type: type });
}

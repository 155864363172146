import axios from "axios";
import config from "../config/env";

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: config.HOST_API_URL });

axiosInstance.defaults.headers.common = {
  ...axiosInstance.defaults.headers.common,
  Authorization: config.API_KEY,
};

axiosInstance.interceptors.response.use(
  (response) => {
    // checkHttpStatus({
    //   code: response.status,
    //   message: response.data?.message,
    // });
    return response;
  },
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || `Something went wrong ${error}`
    )
);

export default axiosInstance;

import { CgChevronDown, CgChevronLeft } from 'react-icons/cg';

export const MenuItems = [
  {
    title: 'Accueil',
    path: '/',
  },

  {
    title: 'Présentation',
    path: '#',
    iconClosed: <CgChevronLeft />,
    iconOpened: <CgChevronDown />,
    subNav: [
      {
        title: 'Mot du  Directeur Général',
        path: '/directeur-general',
        cName: 'sub-nav',
      },
      {
        title: 'Organigramme',
        path: '/organigramme',
        cName: 'sub-nav',
      },
      {
        title: 'Missions',
        path: '/mission',
      },
      {
        title: 'Nos Partenaires',
        path: '/nos-partenaires',
      },
    ],
  },
  {
    title: 'Actualités',
    path: '/actualites',
  },
  {
    title: 'Alertes',
    path: '#',
    iconClosed: <CgChevronLeft />,
    iconOpened: <CgChevronDown />,
    subNav: [
      {
        title: "Bulletins d'alertes de sécurité ",
        path: '/alert',
        cName: 'sub-nav',
      },
      {
        title: 'Communiqués',
        path: '/communique',
        cName: 'sub-nav',
      },
    ],
  },
  {
    title: 'Bonnes Pratiques',
    path: '/goodPractices',
  },
  {
    title: 'Réglementation',
    path: '/publications',
  },
  {
    title: 'Services en ligne',
    path: '#',
    iconClosed: <CgChevronLeft />,
    iconOpened: <CgChevronDown />,
    subNav: [
      // {
      //   title: 'Depots de plaintes',
      //   path: '/services',
      //   cName: 'sub-nav',
      // },
      // {
      //   title: 'Avis d’appels d’offre',
      //   path: '/organigramme',
      //   cName: 'sub-nav',
      // },
      {
        title: 'Signaler un incident',
        path: '/cyber-incident',
        cName: 'sub-nav',
      },
      {
        title: 'Recrutement',
        path: 'handleRecrutementModalShow()',
        cName: 'sub-nav',
        isModal: true,
      },
      // {
      //   title: 'goodPractices',
      //   path: 'handleRecrutementModalShow()',
      //   cName: 'sub-nav',
      //   isModal: true,
      // },

      {
        title: 'Stage',
        path: 'handleStageModalShow()',
        cName: 'sub-nav',
        isModal: true,
      },
      {
        title: 'Suggestions',
        path: 'handleSuggestionModalShow()',
        cName: 'sub-nav',
        isModal: true,
      },
    ],
  },
  {
    title: 'Contact',
    path: '/contact',
  },
];

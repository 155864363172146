import React from 'react';
import { Link } from 'react-router-dom';

export default class FeatureTwo extends React.Component {
  render() {
    let publicUrl = '/';
    return (
      <>
        <section className='feature-two-sec'>
          <div
            className='feature-two__bg'
            style={{
              backgroundImage:
                'url(' +
                publicUrl +
                'assets/images/backgrounds/benefits-v1-bg.jpg)',
            }}
          ></div>
          <div className='shape3 float-bob-y'>
            {/* <img
              src={publicUrl + 'assets/images/shapes/feature-v2-shape1.png'}
              alt=''
            /> */}
          </div>
          <div className='container'>
            <div className='sec-title text-center'>
              <div className='sec-title__tagline'>
                <span className='left'></span>
                <h6>Service en ligne</h6> <span className='right'></span>
              </div>
              <h2 className='sec-title__title'>Signaler un cyber incident </h2>
            </div>
            <div className='row'>
              {/* Start Feature Two Single */}
              <div className='col-xl-4 col-lg-4 col-md-4 text-center'>
                <div className='feature-two__single'>
                  <div className='shape1'></div>
                  <div className='shape2'></div>
                  <div className='icon'>
                    <span className='icon-hotel'></span>
                  </div>
                  <h3>
                    <Link to={'/cyber-incident/public'}>
                      Institution Publique
                    </Link>
                  </h3>
                  {/* <p>Report Incident </p> */}
                  <div className='btn-box'>
                    <Link to={'/cyber-incident/public'}>
                      <span className='text-danger'>Signaler un incident </span>
                    </Link>
                  </div>
                </div>
              </div>
              {/* End Feature Two Single */}

              {/* Start Feature Two Single */}
              <div className='col-xl-4  col-lg-6 col-md-6  text-center'>
                <div className='feature-two__single'>
                  <div className='shape1'></div>
                  <div className='shape2'></div>
                  <div className='icon'>
                    <span className='icon-account'></span>
                  </div>
                  <h3>
                    <Link to={'/cyber-incident/individual'}>Particulier</Link>
                  </h3>
                  <div className='btn-box'>
                    <Link to={'/cyber-incident/individual'}>
                      <span className='text-danger'>Signaler un incident</span>
                    </Link>
                  </div>
                </div>
              </div>
              {/* End Feature Two Single */}

              {/* Start Feature Two Single */}
              <div className='col-xl-4  col-lg-6 col-md-6 text-center'>
                <div className='feature-two__single'>
                  <div className='shape1'></div>
                  <div className='shape2'></div>
                  <div className='icon'>
                    <span className='icon-hotel'></span>
                  </div>
                  <h3>
                    <Link to={'/cyber-incident/entreprise'}>Entreprise</Link>
                  </h3>
                  <div className='btn-box'>
                    <Link to={'/cyber-incident/entreprise'}>
                      <span className='text-danger'>Signaler un incident </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

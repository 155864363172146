import React from 'react';

export default class ContactMap extends React.Component {
  render() {
    return (
      <>
        <div className='contact-page-google-map'>
          <div className='container'>
            <div className='row'>
              <div className='col-xl-12'>
                <iframe
                  src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3900.8195321069966!2d15.03694447423398!3d12.124497188118424!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x111963f0df00064f%3A0x3668c85d2520e752!2s(ANSICE)!5e0!3m2!1sen!2std!4v1717523177126!5m2!1sen!2std'
                  className='contact-page-google-map__one'
                  referrerpolicy='no-referrer-when-downgrade'
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

import React, { useState } from "react";
import { Link } from "react-router-dom";
import SuggestionModal from "../../modals/suggestion";
import StageModal from "../../modals/StageModal";
import RecrutementModal from "../../modals/recrutement";

import { MenuItems } from "../utils/Constants";

const Nav = () => {
  // StageModal modal hanlders
  const [stageModalShow, setStageModalShow] = useState(false);
  const handleStageModalClose = () => setStageModalShow(false);
  // below function is getting called using eval from the MenuItems
  // eslint-disable-next-line no-unused-vars
  const handleStageModalShow = () => setStageModalShow(true);

  // SuggestionModal modal hanlders
  const [suggestionModalShow, setSuggestionModalShow] = useState(false);
  const handleSuggestionModalClose = () => setSuggestionModalShow(false);
  // below function is getting called using eval from the MenuItems
  // eslint-disable-next-line no-unused-vars
  const handleSuggestionModalShow = () => setSuggestionModalShow(true);

  // RecrutementModal modal hanlders
  const [recrutementModalShow, setRecrutementModalShow] = useState(false);
  const handleRecrutementModalClose = () => setRecrutementModalShow(false);
  // below function is getting called using eval from the MenuItems
  // eslint-disable-next-line no-unused-vars
  const handleRecrutementModalShow = () => setRecrutementModalShow(true);
  return (
    <>
      <div className="main-menu text-center">
        <nav>
          <ul className="main-menu__list">
            {MenuItems.map((menu) => {
              return (
                <li className="dropdown">
                  <Link to={menu?.path}>{menu?.title}</Link>
                  {!!menu?.subNav && (
                    <ul>
                      {menu.subNav.map((subMenu) => {
                        return subMenu.isModal ? (
                          <li
                            onClick={() => {
                              // eslint-disable-next-line no-eval
                              eval(subMenu.path);
                            }}
                          >
                            <a>{subMenu?.title}</a>
                          </li>
                        ) : (
                          <li>
                            <Link to={subMenu?.path}>{subMenu?.title}</Link>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
      <SuggestionModal
        {...{
          show: suggestionModalShow,
          handleClose: handleSuggestionModalClose,
        }}
      />
      <StageModal
        {...{ show: stageModalShow, handleClose: handleStageModalClose }}
      />
      <RecrutementModal
        {...{
          show: recrutementModalShow,
          handleClose: handleRecrutementModalClose,
        }}
      />
    </>
  );
};

export default Nav;

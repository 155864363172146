import React from 'react';
import MainLayout from '../../layout/MainLayout';
import FeatureTwo from '../../components/features/FeatureTwo';
export default function ReportAlertPage() {
  let publicUrl = '/';
  return (
    <MainLayout>
      <FeatureTwo />
    </MainLayout>
  );
}

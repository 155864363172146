import React from 'react';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import MainLayout from '../../layout/MainLayout';

import {
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  FacebookIcon,
  LinkedinIcon,
  WhatsappIcon,
} from 'react-share';

function GoodPracticesPage() {
  // const u = `${window.location.origin}/assets/images/flyer/cybersecurity.pdf`;

  return (
    <MainLayout>
      <div classNam e='schedules-area pd-top-110 pd-bottom-120'>
        <Container>
          <Row className='justify-content-center'>
            <Col xl={7} lg={20}>
              <div className='row'>
                <div className='  col-md-10 sec-title text-center'>
                  <div className='sec-title__tagline'>
                    <span className='left'></span>
                    <h6>Les Bonnes Pratiques </h6>
                    <span className='right'></span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <div className='evt-tab-inner text-center'>
            <Tab.Container defaultActiveKey='protection-of-data'>
              <Nav variant='tabs' className='justify-content-center'>
                <Nav.Item>
                  <Nav.Link eventKey='protection-of-data'>
                    PROTECTION DES DONNÉES PERSONNELLES
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='cybersecurity'>CYBERSÉCURITÉ</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='electronic-transactions'>
                    SÉCURITÉ DES TRANSACTIONS ÉLECTRONIQUES.
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Row></Row>
                <Tab.Pane eventKey='protection-of-data'>
                  <div className='tab-content-container'>
                    <iframe
                      src='assets/images/flyer/data-protection.pdf'
                      width='80%'
                      height='1000px'
                      title='Protection of Data'
                      style={{ border: 'none' }}
                    ></iframe>
                  </div>

                  <div className='row mt-3 mr-5'>
                    <div className='col-md-6'>
                      <a
                        className=' p-5 text-primary text-center'
                        href='assets/images/flyer/data-protection.pdf'
                        target='_blank'
                      >
                        Télécharger le Ficher PDF
                      </a>
                    </div>

                    <div className='share-btns text-center  btn-box col-md-4'>
                      <FacebookShareButton
                        quote={
                          'BONNES PRATIQUES EN PROTECTION DES DONNÉES PERSONNELLES'
                        }
                        url={`${window.location.origin}/assets/images/flyer/data-protection.pdf`}
                      >
                        <FacebookIcon className='m-1' size={20} round={true} />
                      </FacebookShareButton>
                      <LinkedinShareButton
                        url={`${window.location.origin}/assets/images/flyer/data-protection.pdf`}
                        title={
                          'BONNES PRATIQUES EN PROTECTION DES DONNÉES PERSONNELLES'
                        }
                      >
                        {console.log(LinkedinShareButton.url)}
                        <LinkedinIcon className='m-1' size={20} round={true} />
                      </LinkedinShareButton>
                      <WhatsappShareButton
                        url={`${window.location.origin}/assets/images/flyer/data-protection.pdf`}
                        title={
                          'BONNES PRATIQUES EN PROTECTION DES DONNÉES PERSONNELLES'
                        }
                      >
                        <WhatsappIcon className='m-1' size={20} round={true} />
                      </WhatsappShareButton>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey='cybersecurity'>
                  <div className='tab-content-container'>
                    <iframe
                      src='assets/images/flyer/cybersecurity.pdf'
                      width='80%'
                      height='1000px'
                      title='Cybersecurity'
                      style={{ border: 'none' }}
                    ></iframe>
                  </div>

                  <div className='row mt-3 mr-5'>
                    <div className='col-md-6'>
                      <a
                        className=' p-5 text-primary text-center'
                        href='assets/images/flyer/cybersecurity.pdf'
                        target='_blank'
                      >
                        Télécharger le Ficher PDF
                      </a>
                    </div>

                    <div className='share-btns text-center btn-box col-md-4'>
                      <FacebookShareButton
                        quote={
                          'BONNES PRATIQUES EN PROTECTION DES DONNÉES PERSONNELLES'
                        }
                        url={`${window.location.origin}/assets/images/flyer/cybersecurity.pdf`}
                      >
                        <FacebookIcon className='m-1' size={20} round={true} />
                      </FacebookShareButton>

                      <LinkedinShareButton
                        url={`${window.location.origin}/assets/images/flyer/cybersecurity.pdf`}
                        title={
                          'BONNES PRATIQUES EN PROTECTION DES DONNÉES PERSONNELLES'
                        }
                      >
                        <LinkedinIcon className='m-1' size={20} round={true} />
                      </LinkedinShareButton>

                      <WhatsappShareButton
                        url={`${window.location.origin}/assets/images/flyer/cybersecurity.pdf`}
                        title={
                          'BONNES PRATIQUES EN PROTECTION DES DONNÉES PERSONNELLES'
                        }
                      >
                        <WhatsappIcon className='m-1' size={20} round={true} />
                      </WhatsappShareButton>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey='electronic-transactions'>
                  <div className='tab-content-container'>
                    <iframe
                      src='assets/images/flyer/Transactions.pdf'
                      width='80%'
                      height='1000px'
                      title='Electronic Transactions'
                      style={{ border: 'none' }}
                    ></iframe>
                  </div>

                  <div className='row mt-3 mr-5'>
                    <div className='col-md-6'>
                      <a
                        className=' p-5 text-primary text-center'
                        href='assets/images/flyer/TRANSACTIONS_ÉLECTRONIQUES.pdf'
                        target='_blank'
                      >
                        Télécharger le Ficher PDF
                      </a>
                    </div>

                    <div className='share-btns text-center  btn-box col-md-4'>
                      <FacebookShareButton
                        url={`${window.location.origin}/assets/images/flyer/TRANSACTIONS_ÉLECTRONIQUES.pdf`}
                        quote={
                          'BONNES PRATIQUES EN PROTECTION DES DONNÉES PERSONNELLES'
                        }
                      >
                        <FacebookIcon className='m-1' size={20} round={true} />
                      </FacebookShareButton>
                      <LinkedinShareButton
                        url={`${window.location.origin}/assets/images/flyer/TRANSACTIONS_ÉLECTRONIQUES.pdf`}
                        title={
                          'BONNES PRATIQUES EN PROTECTION DES DONNÉES PERSONNELLES'
                        }
                      >
                        {console.log(LinkedinShareButton.url)}
                        <LinkedinIcon className='m-1' size={20} round={true} />
                      </LinkedinShareButton>
                      <WhatsappShareButton
                        url={`${window.location.origin}/assets/images/flyer/TRANSACTIONS_ÉLECTRONIQUES.pdf`}
                        title={
                          'BONNES PRATIQUES EN PROTECTION DES DONNÉES PERSONNELLES'
                        }
                      >
                        <WhatsappIcon className='m-1' size={20} round={true} />
                      </WhatsappShareButton>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </Container>
      </div>
    </MainLayout>
  );
}

export default GoodPracticesPage;

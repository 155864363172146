import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axiosInstance from "../../common/utils/axios";
import LoadingOverlay from "react-loading-overlay-ts";
import { showMessage } from "../../Utility";
export default function SuggestionModal({ show, handleClose }) {
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({});

  const handleInputValueChange = (value, key) => {
    setPayload((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleFormSubmit = () => {
    setLoading(true);
    axiosInstance
      .post("send-email", {
        data: {
          ...payload,
          type: "suggestion",
          subject: "Formulaire de suggestions",
        },
      })
      .then((resp) => {
        setLoading(false);
        if (resp?.data?.success) {
          showMessage("Votre message a bien été envoyé", "success");
          handleClose();
        }
      })
      .catch((err) => {
        setLoading(false);
        showMessage("Une erreur est survenue", "error");
        console.log("internship-forms post error", err);
      });
  };

  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <LoadingOverlay active={loading} spinner>
        <>
          <Modal.Header closeButton>
            <Modal.Title className="text-center">
              Formulaire de suggestions
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="m-4">
            <div className="row">
              <div className="col-md-12 row">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Vos Suggestion</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={10}
                    onChange={(e) => {
                      handleInputValueChange(e.target.value, "suggestion");
                    }}
                  />
                </Form.Group>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Form.Control
                    type="text"
                    className="mt-3"
                    placeholder="Nom et Prenom (Optional)"
                    id="inputPassword5"
                    onChange={(e) => {
                      handleInputValueChange(e.target.value, "prenom");
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <Form.Control
                    type="text"
                    className="mt-3"
                    placeholder="email (Facultatifs)"
                    id="inputPassword5"
                    onChange={(e) => {
                      handleInputValueChange(e.target.value, "email");
                    }}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleFormSubmit}>
              Save
            </Button>
          </Modal.Footer>
        </>
      </LoadingOverlay>
    </Modal>
  );
}

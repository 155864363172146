import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import CompanyForm from './forms/companyForm';
import MainLayout from '../../layout/MainLayout';
import PubliqueInstitutionForm from './forms/publicInstitutionForm';
import { useParams } from 'react-router-dom';
import IndividualForm from './forms/individualForm';
const CyberIncidentReportingPage = ({ type }) => {
  const { formType } = useParams();

  return (
    <MainLayout>
      <div className='p-5'>
        <div className='sec-title text-center mb-4x'>
          <div className='sec-title__tagline mb-4'>
            <span className='left'></span>
            <h6>Service en ligne</h6> <span className='right'></span>
          </div>
          <h5 className='mb-4'>Signaler un incident de cybersécurité</h5>
        </div>
        <Container>
          <Row>
            <Col>
              {formType === 'individual' && <IndividualForm />}
              {formType === 'entreprise' && <CompanyForm />}
              {formType === 'public' && <PubliqueInstitutionForm />}
            </Col>
          </Row>
        </Container>
      </div>
    </MainLayout>
  );
};

export default CyberIncidentReportingPage;

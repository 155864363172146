import React from 'react';
import BrandOne from './BrandOne';
// Import only the necessary parts of Bootstrap CSS
// import 'bootstrap/dist/css/bootstrap.min.css';
import './BrandOne.css';

import MainLayout from '../../layout/MainLayout';

export default function BrandOnePage() {
  const publicUrl = '/';
  return (
    <MainLayout>
      <section className='mission-page'>
        <div className='p-5'>
          {/* <div className='sec-title text-center'>
            <div className='sec-title__tagline'>
              <span className='left'></span>
              <h6>Presentation</h6> <span className='right'></span>
            </div>
          </div> */}
          <BrandOne fullDetail={true} />
        </div>
      </section>
    </MainLayout>
  );
}

import React from 'react';
import { Link } from 'react-router-dom';
import MissionList from '../../data/missions.json';
import { removeExtraSpaces } from '../../Utility'; // Import the function

export default class Missions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hoveredMissionId: null,
    };
  }

  handleMouseEnter = (id) => {
    this.setState({ hoveredMissionId: id });
  };

  handleMouseLeave = () => {
    this.setState({ hoveredMissionId: null });
  };

  render() {
    let publicUrl = '/';
    const { hoveredMissionId } = this.state;

    return (
      <>
        <section className='services-one-sec'>
          <div className='container'>
            <div className='sec-title text-center'>
              <div className='sec-title__tagline'>
                <span className='left'></span>
                <h6>Nos Missions</h6> <span className='right'></span>
              </div>
              {/* <h2 className='sec-title__title'>Our Professional Missions</h2> */}
            </div>

            <div className='row'>
              {MissionList.map((mission) => {
                return (
                  <div className='col-xl-4 col-lg-4' key={mission.id}>
                    <div
                      className='services-one__single'
                      onMouseEnter={() => this.handleMouseEnter(mission.id)}
                      onMouseLeave={this.handleMouseLeave}
                    >
                      <div className='services-one__single-img'>
                        <img
                          width={400}
                          height={300}
                          src={
                            hoveredMissionId === mission.id
                              ? publicUrl + mission.imagePath2
                              : publicUrl + mission.imagePath
                          }
                          alt={mission.title}
                        />
                      </div>

                      <div className='services-one__single-content'>
                        <div className='services-one__single-content-title'>
                          <h6>
                            <Link to={`/mission/${mission.id}`}>
                              {mission.title}
                            </Link>
                          </h6>
                        </div>

                        {/* {removeExtraSpaces(item.description)} */}

                        <div>
                          <p className=''>
                            {mission.description.length > 100
                              ? `${removeExtraSpaces(
                                  mission.description.substring(0, 150)
                                )}...`
                              : mission.description}
                            {/* {mission.description.substring(0, 200)}... */}
                          </p>

                          <div className='services-one__single-btn'>
                            <Link
                              to={`/mission/${mission.id}`}
                              className='thm-btn'
                              data-text='Lire Plus +'
                            >
                              Lire Plus +
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </>
    );
  }
}

import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Container, Row, Col } from 'react-bootstrap';
import './BrandOne.css'; // Import the CSS file

export default class BrandOne extends React.Component {
  render() {
    let publicUrl = '/';
    return (
      <>
        <section className='brand-one-sec'>
          <Container>
            <div className='row'>
              <div className='  col-md-10 sec-title text-center'>
                <div className='sec-title__tagline'>
                  <span className='left'></span>
                  <h6>Nos Partenaires</h6> <span className='right'></span>
                </div>
              </div>
            </div>
            <Carousel
              indicators={true}
              controls={true}
              interval={3000}
              pause='hover'
            >
              <Carousel.Item>
                <Row>
                  <Col>
                    <a href='https://securite.gouv.td' target='_blank'>
                      <img
                        width={150}
                        height={150}
                        src={publicUrl + 'assets/images/brand/tchad.svg'}
                        alt='Tchad'
                      />
                    </a>
                  </Col>
                  <Col>
                    <a
                      href='https://www.facebook.com/PoliceTchadienne/?locale=fr_FR'
                      target='_blank'
                    >
                      <img
                        width={150}
                        height={150}
                        src={publicUrl + 'assets/images/brand/police.jpeg'}
                        alt='Police du Tchad'
                      />
                    </a>
                  </Col>
                  <Col>
                    <a href='https://arcep.td' target='_blank'>
                      <img
                        width={150}
                        height={150}
                        src={publicUrl + 'assets/images/brand/arcept.png'}
                        alt='Arcept'
                      />
                    </a>
                  </Col>
                  <Col>
                    <a href='https://adetic.td' target='_blank'>
                      <img
                        width={150}
                        height={150}
                        src={publicUrl + 'assets/images/brand/adetic.png'}
                        alt='Adetic'
                      />
                    </a>
                  </Col>
                  <Col>
                    <a href='https://www.enastic.td' target='_blank'>
                      <img
                        width={150}
                        height={150}
                        src={publicUrl + 'assets/images/brand/enastiv.png'}
                        alt='Enastiv'
                      />
                    </a>
                  </Col>
                </Row>
              </Carousel.Item>

              <Carousel.Item>
                <Row>
                  <Col>
                    <a href='https://www.airtel.td' target='_blank'>
                      <img
                        width={150}
                        height={150}
                        src={publicUrl + 'assets/images/brand/airtel.png'}
                        alt='Airtel Tchad'
                      />
                    </a>
                  </Col>
                  <Col>
                    <a href='https://moovafrica.td' target='_blank'>
                      <img
                        width={150}
                        height={150}
                        src={publicUrl + 'assets/images/brand/tigo.jpeg'}
                        alt='Moov Africa Tchad'
                      />
                    </a>
                  </Col>
                  <Col>
                    <a
                      href='https://www.facebook.com/SudaChad/'
                      target='_blank'
                    >
                      <img
                        width={150}
                        height={150}
                        src={publicUrl + 'assets/images/brand/safitel.png'}
                        alt='Safitel'
                      />
                    </a>
                  </Col>
                  <Col>
                    <a
                      href='https://www.autoritedeprotection.ci/rapdp/'
                      target='_blank'
                    >
                      <img
                        width={150}
                        height={150}
                        src={publicUrl + 'assets/images/brand/Rafricain.jpeg'}
                        alt='Le Réseau africain des autorités de protection des données personnelles (RAPDP)'
                      />
                    </a>
                  </Col>
                  <Col>
                    <a href='https://www.banquemondiale.org/' target='_blank'>
                      <img
                        width={150}
                        height={150}
                        src={
                          publicUrl +
                          'assets/images/brand/logo-wb-header-fr.svg'
                        }
                        alt='Banque Mondial'
                      />
                    </a>
                  </Col>
                </Row>
              </Carousel.Item>

              <Carousel.Item>
                <Row>
                  <Col>
                    <a href='https://www.itu.int' target='_blank'>
                      <img
                        width={150}
                        height={150}
                        src={publicUrl + 'assets/images/brand/ITU.png'}
                        alt='UIT'
                      />
                    </a>
                  </Col>
                  <Col>
                    <a href='https://www.africacert.org' target='_blank'>
                      <img
                        width={150}
                        height={150}
                        src={publicUrl + 'assets/images/brand/africacert.jpeg'}
                        alt='Africa Cert'
                      />
                    </a>
                  </Col>
                  <Col>
                    <a href='https://anssi.bf/' target='_blank'>
                      <img
                        width={150}
                        height={150}
                        src={publicUrl + 'assets/images/brand/ansi_b.png'}
                        alt='ANSI Burkina'
                      />
                    </a>
                  </Col>
                  <Col>
                    <a href='#' target='_blank'>
                      <img
                        width={250}
                        height={130}
                        src={publicUrl + 'assets/images/brand/uaecsc.png'}
                        alt='UAE Cyber security council'
                      />
                    </a>
                  </Col>
                </Row>
              </Carousel.Item>
            </Carousel>
          </Container>
        </section>
      </>
    );
  }
}

import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import FileUpload from "../../components/fileupload/FileUpload";
import axiosInstance from "../../common/utils/axios";
import LoadingOverlayWrapper from "react-loading-overlay-ts";
import { showMessage } from "../../Utility";
export default function StageModal({ show, handleClose }) {
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({});

  const handleInputValueChange = (value, key) => {
    setPayload((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleFormSubmit1 = () => {
    axiosInstance
      .post("internship-forms", {
        data: payload,
      })
      .then((resp) => {})
      .catch((err) => {
        console.log("internship-forms post error", err);
      })
      .finally(() => {
        handleClose();
      });
  };

  const handleFormSubmit = () => {
    setLoading(true);
    axiosInstance
      .post("send-email", {
        data: {
          ...payload,
          type: "internship",
          subject: "Formulaire de demande de stage",
        },
      })
      .then((resp) => {
        setLoading(false);
        if (resp?.data?.success) {
          showMessage("Votre message a bien été envoyé", "success");
          handleClose();
        }
      })
      .catch((err) => {
        setLoading(false);
        showMessage("Une erreur est survenue", "error");
        console.log("internship-forms post error", err);
      });
  };

  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <LoadingOverlayWrapper active={loading} spinner>
        <Modal.Header closeButton>
          <Modal.Title className="text-center">
            Formulaire de demande de stage
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="m-4">
          <div className="row">
            <div className="row col-md-6">
              <Form.Control
                type="text"
                className="mt-3"
                placeholder="Nom"
                id="Nom"
                onChange={(e) => {
                  handleInputValueChange(e.target.value, "name");
                }}
              />
              <Form.Control
                type="text"
                className="mt-3"
                placeholder="Prenom"
                id="Prenom"
                onChange={(e) => {
                  handleInputValueChange(e.target.value, "surname");
                }}
              />
              <Form.Control
                type="email"
                className="mt-3"
                placeholder="email"
                id="email"
                onChange={(e) => {
                  handleInputValueChange(e.target.value, "email");
                }}
              />
              <Form.Control
                type="text"
                className="mt-3"
                placeholder="Telephone"
                id="Telephone"
                maxLength={10}
                minLength={10}
                onChange={(e) => {
                  handleInputValueChange(e.target.value, "telephone");
                }}
              />
            </div>

            <div className="col-md-6">
              <Form.Select
                className="mt-3"
                aria-label="Default select example"
                onChange={(e) => {
                  handleInputValueChange(e.target.value, "type");
                }}
              >
                <option>Type de stage</option>
                <option value="Professionnel">Professionnel</option>
                <option value="Académique">Académique</option>
              </Form.Select>

              <Form.Group
                className="mt-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Control
                  as="textarea"
                  rows={8}
                  placeholder="Message"
                  onChange={(e) => {
                    handleInputValueChange(e.target.value, "message");
                  }}
                />
              </Form.Group>
            </div>
            <div className="col-md-6">
              <FileUpload
                allowedFiles={[".pdf"]}
                label="Télécharger votre demande manuscrite"
                isMultiple={false}
                isServerUpload={true}
                onUploadFinish={(fileurl, filepath) => {
                  handleInputValueChange(filepath?.id, "cv");
                }}
                filepath={payload.cv}
              />
            </div>
            <div className="col-md-6">
              <FileUpload
                allowedFiles={[".pdf"]}
                label="Télécharger votre CV"
                isMultiple={false}
                isServerUpload={true}
                onUploadFinish={(fileurl, filepath) => {
                  handleInputValueChange(filepath?.id, "coverletter");
                }}
                filepath={payload.coverletter}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleFormSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </LoadingOverlayWrapper>
    </Modal>
  );
}

import React, { useState } from 'react';
import * as AiIcons from 'react-icons/ai';
import { AiOutlineBars } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { MenuItems } from '../utils/Constants';
import SuggestionModal from '../../modals/suggestion';
import StageModal from '../../modals/StageModal';
import RecrutementModal from '../../modals/recrutement';

const menuData = MenuItems;

const SidebarLink = styled(Link)`
  display: flex;
  color: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 50px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  &:hover {
    background: #ff5e14;
    color: #fff;
    cursor: pointer;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 16px;
  color: #fff;
  font-weight: 400;
`;

const DropdownLink = styled(Link)`
  height: 60px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  &:hover,
  &:active {
    background: #ff5e14;
    cursor: pointer;
    color: #fff;
  }
`;
const DivDropdownLink = styled.div`
  height: 60px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  &:hover,
  &:active {
    background: #ff5e14;
    cursor: pointer;
    color: #fff;
  }
`;

const SubMenu = ({ item }) => {
  const [subnav, setSubnav] = useState(false);

  const showSubnav = () => setSubnav(!subnav);

  const [stageModalShow, setStageModalShow] = useState(false);
  const handleStageModalClose = () => setStageModalShow(false);
  // below function is getting called using eval from the MenuItems
  // eslint-disable-next-line no-unused-vars
  const handleStageModalShow = () => setStageModalShow(true);

  // SuggestionModal modal hanlders
  const [suggestionModalShow, setSuggestionModalShow] = useState(false);
  const handleSuggestionModalClose = () => setSuggestionModalShow(false);
  // below function is getting called using eval from the MenuItems
  // eslint-disable-next-line no-unused-vars
  const handleSuggestionModalShow = () => setSuggestionModalShow(true);

  // RecrutementModal modal hanlders
  const [recrutementModalShow, setRecrutementModalShow] = useState(false);
  const handleRecrutementModalClose = () => setRecrutementModalShow(false);
  // below function is getting called using eval from the MenuItems
  // eslint-disable-next-line no-unused-vars
  const handleRecrutementModalShow = () => setRecrutementModalShow(true);

  return (
    <>
      <SidebarLink to={`${item.path}`} onClick={item.subNav && showSubnav}>
        <div>
          {item.icon}
          <SidebarLabel>{item.title}</SidebarLabel>
        </div>
        <div>
          {item.subNav && subnav
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null}
        </div>
      </SidebarLink>
      {subnav &&
        item.subNav.map((data, index) =>
          data.isModal ? (
            <DivDropdownLink
              onClick={(e) => {
                e.preventDefault();
                eval(data.path);
              }}
            >
              {item.icon}
              <SidebarLabel>{data.title}</SidebarLabel>
            </DivDropdownLink>
          ) : (
            <DropdownLink key={index} to={`${data.path}`}>
              {item.icon}
              <SidebarLabel>{data.title}</SidebarLabel>
            </DropdownLink>
          )
        )}
      <SuggestionModal
        {...{
          show: suggestionModalShow,
          handleClose: handleSuggestionModalClose,
        }}
      />
      <StageModal
        {...{ show: stageModalShow, handleClose: handleStageModalClose }}
      />
      <RecrutementModal
        {...{
          show: recrutementModalShow,
          handleClose: handleRecrutementModalClose,
        }}
      />
    </>
  );
};

const NavIcon = styled(Link)`
  color: #ff5e14;
  font-size: 2rem;
  height: 50px;
  display: flex;
  align-items: center;
  margin-left: 20px;
`;

const SidebarNav = styled.nav`
  background-color: #001659;
  width: 300px;
  height: 100%;
  position: fixed;
  overflow-y: scroll;
  scroll-behavior: smooth;
  -webkit-scroll-behavior: smooth;
  box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.15);
  top: 0;
  right: ${({ sidebar }) => (sidebar ? '0' : '-100%')};
  transition: 350ms;
  z-index: 99999;
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

const MobileMenu = () => {
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);

  return (
    <>
      <NavIcon to='#' style={{ justifyContent: 'flex-end' }}>
        <AiOutlineBars onClick={showSidebar} />
      </NavIcon>

      <SidebarNav sidebar={sidebar}>
        <SidebarWrap>
          <div
            className='mobile-nav__content'
            style={{
              backgroundColor: '#0053A0',
            }}
          >
            {/* <div className='logo-box'>
                <Link to={`/`} aria-label='logo image'>
                  <img
                    src={publicUrl + 'assets/images/resources/logo-1.png'}
                    width='155'
                    alt=''
                  />
                </Link>
              </div> */}
            <NavIcon to='#'>
              <AiIcons.AiOutlineClose
                style={{
                  color: 'white',
                  fontSize: '18px',
                  justifyContent: 'flex-start',
                }}
                onClick={showSidebar}
              />
            </NavIcon>
            {menuData.map((item, index) => (
              <SubMenu item={item} key={index} />
            ))}
            <ul className='mobile-nav__contact list-unstyled'>
              <li>
                <i className='fa fa-envelope' aria-hidden='true'></i>
                <a href='mailto:contact@ansice.td'>contact@ansice.td</a>
              </li>
              <li>
                <i className='fa fa-phone' aria-hidden='true'></i>
                <a href='tel:+23567313190'>
                  Tél: +235 97 31 31 90 | 67 31 31 90
                </a>
              </li>
            </ul>
            <div className='mobile-nav__top'>
              <div className='mobile-nav__social'>
                <a href='https://twitter.com/'>
                  <span className='fab fa-twitter'></span>
                </a>
                <a href='https://www.facebook.com/p/ANSICE-TCHAD-100059938052202/'>
                  <span className='fab fa-facebook-square'></span>
                </a>

                <a
                  href='https://td.linkedin.com/company/ansice'
                  target='_blank'
                >
                  <span className='first icon-linkedin'></span>
                  <span className='second icon-linkedin'></span>
                </a>
              </div>
            </div>
          </div>
        </SidebarWrap>
      </SidebarNav>
    </>
  );
};

export default MobileMenu;

import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import WOW from "wowjs";
import { FaAngleUp } from "react-icons/fa";
import routes from "./routes";

//Pages
import Error from "./pages/Error";
import ScrollToTopRoute from "./components/scroll-to-top-route/ScrollToTopRoute";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

function App() {
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);
  return (
    <Router>
      <ScrollToTopRoute />
      <Routes>
        {routes.map((route) => (
          <Route path={route.path} exact element={route.component} />
        ))}
        {/* <Route path={`/`} exact element={<HomeThree />} />
        <Route path={`/home-two`} exact element={<HomeThree />} />
        <Route path={`/home-three`} exact element={<HomeThree />} />
        <Route path={`/home-four`} exact element={<HomeFour />} />
        <Route path={`/home-five`} exact element={<HomeFive />} />
        <Route path={`/about-one`} exact element={<AboutOne />} />
        <Route path={`/about-two`} exact element={<AboutTwo />} />
        <Route path={`/services`} exact element={<ServicesPage />} />
        <Route
          path={`/general-contracting`}
          exact
          element={<GeneralContracting />}
        />
        <Route
          path={`/metrial-managment`}
          exact
          element={<MetrialManagment />}
        />
        <Route
          path={`/building-renovation`}
          exact
          element={<BuildingRenovation />}
        />
        <Route
          path={`/architecture-design`}
          exact
          element={<ArchitectureDesign />}
        />
        <Route path={`/multistory-build`} exact element={<MultistoryBuild />} />
        <Route path={`/portfolio`} exact element={<PortfolioPage />} />
        <Route
          path={`/portfolio-details`}
          exact
          element={<PortfolioDetails />}
        />
        <Route path={`/team`} exact element={<TeamPage />} />
        <Route path={`/team-details`} exact element={<TeamDetails />} />
        <Route path={`/testimonials`} exact element={<TestimonialsPage />} />
        <Route path={`/pricing`} exact element={<PricingPage />} />
        <Route path={`/faq`} exact element={<FaqPage />} />
        <Route path={`/blog`} exact element={<BlogPage />} />
        <Route path={`/blog-grid`} exact element={<BlogGridPage />} />
        <Route path={`/blog-list`} exact element={<BlogListPage />} />
        <Route path={`/blog-standard`} exact element={<BlogStandardPage />} />
        <Route path={`/blog-details`} exact element={<BlogDetails />} />
        <Route path={`/contact`} exact element={<ContactPage />} /> */}
        <Route path={`/*`} exact element={<Error />} />
      </Routes>
      <ScrollToTop
        className="scrollUp"
        smooth
        top="1500"
        component={<FaAngleUp />}
      />
    </Router>
  );
}

export default App;
